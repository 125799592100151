import _ from 'lodash';
import { handleActions } from 'redux-actions';

import actions from './actions';

const defaultState = {
  clients: null,
  transaction: null,
  transactionEvent: null,
  transactionEvents: null,
};

export default handleActions(
  {
    [actions.fetchClientsSuccess]: (state, { payload }) => ({ ...state, clients: payload, }),

    [actions.fetchSubtruckTransactionSuccess]: (state, { payload }) => ({ ...state, transaction: _.isArray(payload) ? null : payload, }), // TODO isArray check is hack for fetch-error case
    [actions.clearSubtruckTransaction]: (state, { payload }) => ({ ...state, transaction: null, }),

    [actions.fetchSubtruckTransactionEventsSuccess]: (state, { payload }) => {
      return {
        ...state,
        transactionEvents: payload,
      };
    },
    [actions.clearSubtruckTransactionEvents]: (state, { payload }) => {
      return {
        ...state,
        transactionEvents: null,
      };
    },
    [actions.fetchSubtruckTransactionEventSuccess]: (state, { payload }) => {
      return {
        ...state,
        transactionEvent: _.isArray(payload) ? null : payload,
      };
    },
    [actions.clearSubtruckTransactionEvent]: (state, { payload }) => {
      return {
        ...state,
        transactionEvent: null,
      };
    },
  },
  defaultState
)
