import React from 'react';
import ReactDOM from 'react-dom';
import { connect, } from 'react-redux';
import { compose, defaultProps, lifecycle, } from 'recompose';
import { Debounce, } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import moment from 'moment';

import { AppActions, MagnusActions, RevenueActions, FinanceActions, SettingsActions, } from 'src/redux/actions';

import { SpinFullscreen, Layout, } from 'src/components/ui';

import Maintenance from '../../screens/public/maintenance';

import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import ModalFormPortal from '../ModalForm';

import AppRouter from './AppRouter';
import AppHolder from './AppHolder';

import './global.css';



const SpinPortal = compose(
  connect(
    state => ({
      spinning: state.app.spinner,
    }),
  ),
  defaultProps({
    container: document.createElement('div')
  }),
  lifecycle({
    componentDidMount() {
      document.body.appendChild(this.props.container);
    },
    componentWillUnmount() {
      document.body.removeChild(this.props.container);
    }
  }),
)(({ container, spinning }) => {
  return ReactDOM.createPortal(spinning ? <SpinFullscreen spinning={true} /> : null, container);
});

const Component = ({
  match: { url },
  height,
  serverMaintenance,
  title,
  ...props
}) => (
  <AppHolder>
    <Layout style={{ height: window.innerHeight }}>
      <Debounce time="1000" handler="onResize">
        <WindowResizeListener
          onResize={windowSize => props.toggleAll({ width: windowSize.windowWidth, height: windowSize.windowHeight, })}
        />
      </Debounce>
      <Topbar url={url} />
      <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
        <Sidebar url={url} />
        <Layout className="isoContentMainLayout" style={{ height: height }}>
          <Layout.Content
            className="isomorphicContent"
            style={{
              padding: '70px 0 0',
              flexShrink: '0',
              position: 'relative',
              background: '#fff'
            }}
          >
            {
              serverMaintenance
                ? <Maintenance />
                : <AppRouter url={url} />
            }
          </Layout.Content>
          <Layout.Footer style={{ background: '#ffffff', textAlign: 'center', borderTop: '1px solid #ededed' }}>
            <span>Magnus {process.env.REACT_APP_VERSION} ©{moment().format('YYYY')}</span>
          </Layout.Footer>
        </Layout>
      </Layout>
    </Layout>
    <ModalFormPortal />
    <SpinPortal />
  </AppHolder>
);

export default compose(
  connect(
    state => ({
      height: state.app.height,
      serverMaintenance: state.remoteConfig.serverMaintenance,
    }),
    {
      toggleAll: AppActions.toggleAll,
      fetchCountries: MagnusActions.fetchCountries,
      fetchRevenueNetworks: RevenueActions.fetchRevenueNetworks,
      fetchFinanceNetworks: FinanceActions.fetchFinanceNetworks,
      fetchUserSettings: SettingsActions.fetchSettings,
    }
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchCountries();
      this.props.fetchRevenueNetworks();
      this.props.fetchFinanceNetworks();
      this.props.fetchUserSettings();
    },
  }),
)(Component);
