import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = _ => resolve(reader.result);

    reader.readAsText(file);
  });
}

function* sagaCreateCreative() {
  while (true) {
    const { payload } = yield take(actions.createCreative);

    const content = yield call(readFile, payload.content.file);

    const args = {
      name: payload.name,
      app_project_id: payload.app_project_id,
      content: content,
    };

    const rs = yield call(API.createVariatorCreative, args);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Creative ${payload.name} was added.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchCreatives({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaUpdateCreative() {
  while (true) {
    const { payload } = yield take(actions.updateCreative);

    let content;

    if (payload.content) {
      content = yield call(readFile, payload.content.file);
    }

    const args = {
      id: payload.id,
      name: payload.name,
      app_project_id: payload.app_project_id,
      content: content,
    };

    const rs = yield call(API.updateVariatorCreative, args);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Creative ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchCreatives({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaDeleteCreative() {
  while (true) {
    const { payload } = yield take(actions.deleteCreative);

    const rs = yield call(API.deleteVariatorCreative, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: rs.response && rs.response.status === 409
          ? 'You can’t delete this creative if it assigned to the campaign.'
          : getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Creative ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchCreatives({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

const sagas = [
  createFetchGenerator('fetchCreatives', 'fetchVariatorCreatives', actions),

  sagaCreateCreative,
  sagaUpdateCreative,
  sagaDeleteCreative,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
