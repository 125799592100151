import _ from 'lodash';
import { createActions, } from 'redux-actions';

import API from 'src/service/api';

import { createSuccessErrorActions, } from '../../helpers';

let asyncActions = {
  'FETCH_STATISTICS': 'fetchMutatorStatistics',
  'FETCH_DEVICE_LOG': 'fetchMutatorDeviceLog',
};

asyncActions = _.mapValues(asyncActions, (item) => (
  async (...args) => {
    const rs = await API[item](...args);
    if (rs instanceof Error) throw rs;
    return rs;
  }
));

export default createActions(

  { ...asyncActions },

  ...createSuccessErrorActions('FETCH_EXPERIMENTS'),

  'CREATE_EXPERIMENT',
  'DELETE_EXPERIMENT',
  'UPDATE_EXPERIMENT',

  ...createSuccessErrorActions('FETCH_EXPERIMENT_HISTORY'),

  'CLEAR',

  { prefix: 'MUTATOR/EXPERIMENTS', }

);
