/* eslint-disable jsx-a11y/accessible-emoji */

import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter, } from 'react-router';
import { connect } from 'react-redux';
import { compose, lifecycle, } from 'recompose';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { SECTIONS, ICONS, } from 'src/constants/sections';

import { mapActions, AppActions } from 'src/redux/actions';
import { serializeCompany } from 'src/redux/auth/utils';
import { hasPermissions } from 'src/helpers/permissions';

import { Layout, Menu, } from 'src/components/ui';
import Scrollbars from 'src/components/utility/customScrollBar';

import SidebarWrapper from './style';

import CompanySelect from './CompanySelect';
import ProjectSelect from './ProjectSelect';

import { NewYear } from './NewYear';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const stripTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

const CurrentLink = ({ to, checkPath, exact, pathname, children, title, style }) => {
  if (exact ? (checkPath || to) === pathname : ~pathname.indexOf(checkPath || to)) {
    return (<a style={style} title={title || null}>{children}</a>) // eslint-disable-line jsx-a11y/anchor-is-valid
  } else {
    return (<Link to={to} style={style} title={title || null}>{children}</Link>)
  }
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
    const m = moment();
    this.newYear = m.isAfter(moment('12-19', 'MM-DD')) || m.isBefore(moment('01-15', 'MM-DD'))
  }
  handleClick(e) {
    this.props.changeCurrent([e.key]);

    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  }
  getAncestorKeys = key => {
    const map = {
      sub3: ["sub2"]
    };
    return map[key] || [];
  };

  render() {
    const { app, toggleOpenDrawer, height, location, companies, } = this.props;
    const collapsed = _.cloneDeep(app.collapsed) && !_.cloneDeep(app.openDrawer);
    const { openDrawer } = app;
    const { pathname } = location;
    const mode = collapsed === true ? 'vertical' : 'inline';

    const url = stripTrailingSlash(this.props.url);

    const cname = serializeCompany(_.map(companies, 'id'));

    const inSDK = !!app.sdkProject;

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={260}
          className="isomorphicSidebar"
          onMouseEnter={_ => !openDrawer && toggleOpenDrawer()}
          onMouseLeave={_ => openDrawer && toggleOpenDrawer()}
        >
          <div className="isoAltColorWrapper" style={{ width: inSDK ? '100%' : '0%' }}></div>
          <div className="isoLogoWrapper" onClick={this.handleClick} key={''}>
            {collapsed ? (
              <div>
                <h3>
                  <CurrentLink to={`/`} pathname={pathname} checkPath={`${url}`} exact={true}>
                    <i className={'ion-ios-flash'} />
                  </CurrentLink>
                </h3>
              </div>
            ) : (
              <h3>
                <CurrentLink to={"/"} pathname={pathname} checkPath={`${url}`} exact={true}><i className={'ion-ios-flash'} /> Magnus</CurrentLink>
                {inSDK &&
                  <CurrentLink to={`/${cname}/sdk`} pathname={pathname} checkPath={`${url}`} exact={true} style={{ fontWeight: 400, }}> SDK</CurrentLink>
                }
              </h3>
            )}
          </div>
          <Scrollbars style={{ height: height - 70 }}>
            {collapsed
              ? <div style={{ height: 51 }} />
              : inSDK
                ? <ProjectSelect />
                : <CompanySelect />
            }
            <Menu
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : [...app.openKeys, ...(inSDK ? [] : [`${cname}/${SECTIONS.ANALYTICS}`, `${cname}/${SECTIONS.MARKETING}`])]}
              selectedKeys={app.current}
              onClick={this.handleClick}
              onOpenChange={this.onOpenChange}
            >
              {!inSDK && hasPermissions(['analytics/overview', 'analytics/monetization']) && (
                <SubMenu
                  key={`${cname}/${SECTIONS.ANALYTICS}`}
                  title={
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.ANALYTICS]} />
                      <span className="nav-text">ANALYTICS</span>
                    </span>
                  }
                >
                  {hasPermissions(['analytics/overview']) && (
                    <Menu.Item key={`${cname}/${SECTIONS.ANALYTICS}/overview`}>
                      <CurrentLink
                        to={`${url}/${cname}/${SECTIONS.ANALYTICS}/overview`}
                        pathname={pathname}
                        checkPath={`${url}/${cname}/${SECTIONS.ANALYTICS}/overview`}
                      >
                        <span>Overview</span>
                      </CurrentLink>
                    </Menu.Item>
                  )}
                  {hasPermissions(['analytics/monetization']) && (
                    <Menu.Item key={`${cname}/${SECTIONS.ANALYTICS}/monetization`}>
                      <CurrentLink
                        to={`${url}/${cname}/${SECTIONS.ANALYTICS}/monetization`}
                        pathname={pathname}
                        checkPath={`${url}/${cname}/${SECTIONS.ANALYTICS}/monetization`}
                      >
                        <span>Monetization</span>
                      </CurrentLink>
                    </Menu.Item>
                  )}
                  {hasPermissions(['analytics/revenue-forecast']) && (
                    <Menu.Item key={`${cname}/${SECTIONS.ANALYTICS}/revenue-forecast`}>
                      <CurrentLink
                        pathname={pathname}
                        to={`${url}/${cname}/${SECTIONS.ANALYTICS}/revenue-forecast`}
                      >
                        <span>Revenue Forecast</span>
                      </CurrentLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {!inSDK && hasPermissions(['marketing/view']) && (
                <SubMenu
                  key={`${cname}/${SECTIONS.MARKETING}`}
                  title={
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.MARKETING]} />
                      <span className="nav-text">MARKETING</span>
                    </span>
                  }
                >
                  {hasPermissions(['marketing/view']) && (
                    <Menu.Item key={`${cname}/${SECTIONS.MARKETING}/dashboard`}>
                      <CurrentLink
                        to={`${url}/${cname}/${SECTIONS.MARKETING}/dashboard`}
                        pathname={pathname}
                        checkPath={`${url}/${cname}/${SECTIONS.MARKETING}/dashboard`}
                        exact
                      >
                        <span>Dashboard</span>
                      </CurrentLink>
                    </Menu.Item>
                  )}
                  {hasPermissions(['marketing/view']) && (
                    <Menu.Item key={`${cname}/${SECTIONS.MARKETING}/cohort-report`}>
                      <CurrentLink
                        pathname={pathname}
                        to={`${url}/${cname}/${SECTIONS.MARKETING}/cohort-report`}
                        exact>
                        <span>Cohort Report</span>
                      </CurrentLink>
                    </Menu.Item>
                  )}
                  {hasPermissions(['marketing/view']) && (
                    <Menu.Item key={`${cname}/${SECTIONS.MARKETING}/timeline-report`}>
                      <CurrentLink
                        pathname={pathname}
                        to={`${url}/${cname}/${SECTIONS.MARKETING}/timeline-report`}
                        exact>
                        <span>Timeline Report</span>
                      </CurrentLink>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {!inSDK && hasPermissions(['sdk/variator/view', 'sdk/variator/manage', 'sdk/mutator/view', 'sdk/mutator/manage', 'sdk/subtruck/view', 'sdk/subtruck/manage', 'sdk/evtruck/view', 'sdk/evtruck/manage',]) && (
                <Menu.Item key={`${cname}/${SECTIONS.SDK}`}>
                  <CurrentLink
                    pathname={pathname}
                    to={`${url}/${cname}/${SECTIONS.SDK}`}
                    exact
                  >
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.SDK]} />
                      <span className="nav-text">SDK</span>
                    </span>
                  </CurrentLink>
                </Menu.Item>
              )}
              {inSDK && hasPermissions(['sdk/variator/view', 'sdk/variator/manage']) && (
                <SubMenu
                  key={`${SECTIONS.VARIATOR}`}
                  title={
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.VARIATOR]} />
                      <span className="nav-text">VARIATOR</span>
                    </span>}
                >
                  <Menu.Item key={`${SECTIONS.VARIATOR}/campaigns`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/campaigns`}
                      exact
                    >
                      <span>Campaings</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.VARIATOR}/triggers`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/triggers`}
                      exact
                    >
                      <span>Triggers</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.VARIATOR}/creatives`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/creatives`}
                      exact
                    >
                      <span>Creatives</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.VARIATOR}/products`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/products`}
                      exact
                    >
                      <span>Products</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.VARIATOR}/statistics`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/statistics`}
                      exact
                    >
                      <span>Statistics</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.VARIATOR}/promo-targets`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/promo-targets`}
                      exact
                    >
                      <span>Promo Targets</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.VARIATOR}/instructions`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.VARIATOR}/instructions`}
                    >
                      <span>Instructions</span>
                    </CurrentLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {inSDK && hasPermissions(['sdk/mutator/view', 'sdk/mutator/manage']) && (
                <SubMenu
                  key={`${SECTIONS.MUTATOR}`}
                  title={
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.MUTATOR]} />
                      <span className="nav-text">MUTATOR</span>
                    </span>
                  }
                >
                  <Menu.Item key={`${SECTIONS.MUTATOR}/config`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.MUTATOR}/config`}
                      exact
                    >
                      <span>Config</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.MUTATOR}/history`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.MUTATOR}/history`}
                      exact
                    >
                      <span>History</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.MUTATOR}/experiments`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.MUTATOR}/experiments`}
                      exact
                    >
                      <span>Experiments</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.MUTATOR}/instructions`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.MUTATOR}/instructions`}
                      exact
                    >
                      <span>Instructions</span>
                    </CurrentLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {inSDK && hasPermissions(['sdk/subtruck/view', 'sdk/subtruck/manage']) && (
                <SubMenu
                  key={`${SECTIONS.SUBTRUCK}`}
                  title={
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.SUBTRUCK]} />
                      <span className="nav-text">SUBTRUCK</span>
                    </span>
                  }
                >
                  <Menu.Item key={`${SECTIONS.SUBTRUCK}/transactions`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.SUBTRUCK}/transactions`}
                      exact
                    >
                      <span>Transactions</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.SUBTRUCK}/fails`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.SUBTRUCK}/fails`}
                      exact
                    >
                      <span>Fails</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.SUBTRUCK}/sandbox`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.SUBTRUCK}/sandbox`}
                      exact
                    >
                      <span>Sandbox</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.SUBTRUCK}/instructions`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.SUBTRUCK}/instructions`}
                    >
                      <span>Instructions</span>
                    </CurrentLink>
                  </Menu.Item>
                </SubMenu>
              )}
              {inSDK && hasPermissions(['sdk/evtruck/view', 'sdk/evtruck/manage']) && (
                <SubMenu
                  key={`${SECTIONS.EVTRUCK}`}
                  title={
                    <span className="isoMenuHolder">
                      <i className={ICONS[SECTIONS.EVTRUCK]} />
                      <span className="nav-text">EVTRUCK</span>
                    </span>
                  }
                >
                  <Menu.Item key={`${SECTIONS.EVTRUCK}/dashboards`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.EVTRUCK}/dashboards`}
                      exact
                    >
                      <span>Dashboards</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.EVTRUCK}/charts`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.EVTRUCK}/charts`}
                      exact
                    >
                      <span>Charts</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.EVTRUCK}/events`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.EVTRUCK}/events`}
                      exact
                    >
                      <span>Events</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.EVTRUCK}/segments`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.EVTRUCK}/segments`}
                      exact
                    >
                      <span>Segments</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.EVTRUCK}/device-logs`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.EVTRUCK}/device-logs`}
                      exact
                    >
                      <span>Device Logs</span>
                    </CurrentLink>
                  </Menu.Item>
                  <Menu.Item key={`${SECTIONS.EVTRUCK}/instructions`}>
                    <CurrentLink
                      pathname={pathname}
                      to={`${url}/${cname}/${SECTIONS.SDK}/${app.sdkProject}/${SECTIONS.EVTRUCK}/instructions`}
                    >
                      <span>Instructions</span>
                    </CurrentLink>
                  </Menu.Item>
                </SubMenu>
              )}
            </Menu>
          </Scrollbars>
          {this.newYear && <NewYear />}
        </Sider>
      </SidebarWrapper >
    );
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      app: state.app,
      height: state.app.height,
      companies: state.auth.companies,
    }),
    mapActions([
      'toggleOpenDrawer',
      'changeOpenKeys',
      'changeCurrent',
      'toggleCollapsed',
      'setSDKProject',
    ], AppActions)
  ),
  lifecycle({
    componentDidMount() {
      const { location, changeCurrent, changeOpenKeys, setSDKProject, } = this.props;

      let routes = location.pathname.split('/');
      const project = routes[2] === 'sdk' && !!routes[3] ? routes[3] : null;

      if (project) {
        routes = routes.slice(3);
      }

      changeOpenKeys([
        routes[1],
        routes[1] + '/' + routes[2],
      ]);
      changeCurrent([
        routes[1] + '/' + routes[2],
        routes[1] + '/' + routes[2] + '/' + routes[3],
      ]);
      setSDKProject(project);
    },
    componentDidUpdate(prevProps) {
      const { location, changeCurrent, changeOpenKeys, setSDKProject, } = this.props,
        { location: prevLocation, } = prevProps;

      if (location.pathname !== prevLocation.pathname) {
        let routes = location.pathname.split('/');
        const project = routes[2] === 'sdk' && !!routes[3] ? routes[3] : null;

        if (project) {
          routes = routes.slice(3);
        }

        changeOpenKeys([
          routes[1],
          routes[1] + '/' + routes[2],
        ]);
        changeCurrent([
          routes[1] + '/' + routes[2],
          routes[1] + '/' + routes[2] + '/' + routes[3],
        ]);
        setSDKProject(project);
      }
    },
    componentWillUnmount() {
      const { changeCurrent, changeOpenKeys, setSDKProject, } = this.props;

      changeCurrent([null]);
      changeOpenKeys([]);
      setSDKProject(null);
    }
  }),
)(Sidebar);
