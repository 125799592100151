import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  experiments: null,
  history: null,
};

export default handleActions(
  {
    [actions.fetchExperimentsSuccess]: (state, { payload }) => ({ ...state, experiments: payload, }),
    [actions.fetchExperimentHistorySuccess]: (state, { payload }) => ({ ...state, history: payload, }),
    [actions.clear]: () => defaultState,
  },
  defaultState
)
