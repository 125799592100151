import { combineReducers } from 'redux';

import config from './config/reducer';
import experiments from './experiments/reducer';
import jsonSchema from './json-schema/reducer';

export default combineReducers({
  config,
  experiments,
  jsonSchema,
});

