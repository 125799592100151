import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaCreateExperiment() {
  while (true) {
    const { payload } = yield take(actions.createExperiment);

    payload.status = 'draft';

    const rs = yield call(API.createMutatorExperiment, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Experiment ${payload.name} was created.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchExperiments({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaUpdateExperiment() {
  while (true) {
    const { payload } = yield take(actions.updateExperiment);

    const rs = yield call(API.updateMutatorExperiment, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Experiment ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchExperiments({ app_project_ids: [payload.app_project_id], }));
    }
  }
}

function* sagaDeleteExperiment() {
  while (true) {
    const { payload } = yield take(actions.deleteExperiment);

    const rs = yield call(API.deleteMutatorExperiment, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Experiment ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchExperiments({ app_project_ids: [payload.app_project_id], }));
    }
  }
}

const sagas = [
  createFetchGenerator('fetchExperiments', 'fetchMutatorExperiments', actions),

  sagaCreateExperiment,
  sagaUpdateExperiment,
  sagaDeleteExperiment,

  createFetchGenerator('fetchExperimentHistory', 'fetchMutatorExperimentHistory', actions),
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
