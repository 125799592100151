import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';

function* sagaUpdateDashboard() {
  while (true) {
    const { payload } = yield take(actions.updateDashboard);

    const rs = yield call(API.updateEvTruckDashboard, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Dashboard ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchDashboards({ app_project_id: payload.app_project_id, }));
    }
  }
}

function* sagaDeleteDashboard() {
  while (true) {
    const { payload } = yield take(actions.deleteDashboard);

    const rs = yield call(API.deleteEvTruckDashboard, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Dashboard ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchDashboards({ app_project_id: payload.app_project_id, }));
    }
  }
}

const sagas = [

  createFetchGenerator('fetchDashboards', 'fetchEvTruckDashboards', actions),

  sagaUpdateDashboard,
  sagaDeleteDashboard,

];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
