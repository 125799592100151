import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaCreateCampaign() {
  while (true) {
    const { payload } = yield take(actions.createCampaign);

    const args = _.pick(payload, [
      'name',
      'type',
      'active',
      'app_project_id',
      'placement_id',
      'creative_id',
      'promo_target_id',
      'priority',
      'view_limit',
      'view_offset',
      'view_interval',
      'new_users_only',
      'variants',
    ]);

    const rs = yield call(API.createVariatorCampaign, args);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Campaign ${payload.name} was created.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchCampaigns({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaUpdateCampaign() {
  while (true) {
    const { payload } = yield take(actions.updateCampaign);

    let segments = payload.segments;
    payload.segments = [];
    _.forEach(segments, (s) => {
      payload.segments.push(_.pick(s, 'id', 'option', 'value', 'condition', 'type'));
    });

    const rs = yield call(API.updateVariatorCampaign, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Campaign ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchCampaigns({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaDeleteCampaign() {
  while (true) {
    const { payload } = yield take(actions.deleteCampaign);

    const rs = yield call(API.deleteVariatorCampaign, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Campaign ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchCampaigns({ app_project_ids: [payload.app_project_id] }));
    }
  }
}


function* sagaActivateCampaign() {
  while (true) {
    const { payload } = yield take(actions.activateCampaign);

    const args = {
      id: payload.id,
    };

    const rs = yield call(API.activateVariatorCampaign, args);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Campaign ${payload.name} was activated.`,
      }));

      yield put(actions.fetchCampaigns({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaDeactivateCampaign() {
  while (true) {
    const { payload } = yield take(actions.deactivateCampaign);

    const args = {
      id: payload.id,
    };

    const rs = yield call(API.deactivateVariatorCampaign, args);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Campaign ${payload.name} was deactivated.`,
      }));

      yield put(actions.fetchCampaigns({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

const sagas = [
  createFetchGenerator('fetchCampaigns', 'fetchVariatorCampaigns', actions),
  createFetchGenerator('fetchCampaignsTypes', 'fetchVariatorCampaignsTypes', actions),

  sagaCreateCampaign,
  sagaUpdateCampaign,
  sagaDeleteCampaign,

  sagaActivateCampaign,
  sagaDeactivateCampaign,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
