import { all, call, fork, take, put, select, } from 'redux-saga/effects';

import API from 'src/service/api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaUpdateSchema() {
  while (true) {
    const { payload } = yield take(actions.updateSchema);

    const schema = yield select(state => state.mutator.jsonSchema.schema);

    let rs;

    if (payload?.schema) {
      if (schema) {
        rs = yield call(API.updateMutatorJsonSchema, payload);
      } else {
        rs = yield call(API.createMutatorJsonSchema, payload);
      }
    } else {
      if (schema) {
        rs = yield call(API.deleteMutatorJsonSchema, payload);
      } else {
        rs = Promise.resolve();
      }
    }


    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `JSON Schema was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchSchema({ app_project_id: payload.app_project_id, }));
    }
  }
}


const sagas = [
  createFetchGenerator('fetchSchema', 'fetchMutatorJsonSchema', actions),

  sagaUpdateSchema,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
