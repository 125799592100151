import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaUpdateSavedChart() {
  while (true) {
    const { payload } = yield take(actions.updateSavedChart);

    const rs = yield call(API.updateEvTruckChart, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Chart ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchSavedCharts({ app_project_id: payload.app_project_id, }));
    }
  }
}

function* sagaDeleteSavedChart() {
  while (true) {
    const { payload } = yield take(actions.deleteSavedChart);

    const rs = yield call(API.deleteEvTruckChart, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Chart ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchSavedCharts({ app_project_id: payload.app_project_id, }));
    }
  }
}

const sagas = [

  createFetchGenerator('fetchSavedCharts', 'fetchEvTruckCharts', actions),

  sagaUpdateSavedChart,
  sagaDeleteSavedChart,

];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
