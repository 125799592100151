import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaCreateSegment() {
  while (true) {
    const { payload } = yield take(actions.createSegment);

    const rs = yield call(API.createEvTruckSegment, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Segment ${payload.name} was created.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchSegments({ app_project_id: payload.app_project_id, }));
    }
  }
}

function* sagaUpdateSegment() {
  while (true) {
    const { payload } = yield take(actions.updateSegment);

    const rs = yield call(API.updateEvTruckSegment, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Segment ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchSegments({ app_project_id: payload.app_project_id, }));
    }
  }
}

function* sagaDeleteSegment() {
  while (true) {
    const { payload } = yield take(actions.deleteSegment);

    const rs = yield call(API.deleteEvTruckSegment, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Segment ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchSegments({ app_project_id: payload.app_project_id, }));
    }
  }
}

const sagas = [

  createFetchGenerator('fetchSegments', 'fetchEvTruckSegments', actions),

  sagaCreateSegment,
  sagaUpdateSegment,
  sagaDeleteSegment,
  
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
