import { createActions } from 'redux-actions';

export default createActions(
  'TOGGLE_COLLAPSED',
  'TOGGLE_ALL',

  'TOGGLE_OPEN_DRAWER',

  'CHANGE_OPEN_KEYS',
  'CHANGE_CURRENT',

  'SET_S_D_K_PROJECT',

  'SHOW_SPINNER',
  'HIDE_SPINNER',

  'SET_TITLE',

  'TOGGLE_MODAL_FORM',
  'DROP_MODAL_FORM',
  'SET_MODAL_FORM_ERROR',

  'SHOW_NOTIFICATION',

  { prefix: 'APP', }
);
