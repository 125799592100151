import _ from 'lodash';
import { all, call, fork, put, take } from 'redux-saga/effects';

import { getApiErrorText } from 'src/helpers/errors-api';

import API from 'src/service/api';

import { createFetchGenerator } from '../helpers';

import { AppActions } from '../actions';

import actions from './actions';


function* sagaCreateFinanceExpenses() {
  while (true) {
    const { payload } = yield take(actions.createFinanceExpenses);

    const rs = yield call(API.createFinanceExpenses, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Expenses ${payload.name} were added.`,
      }));

      yield put(actions.fetchFinanceExpenses({ app_ids: payload.app_ids, }));
    }
  }
}

function* sagaUpdateFinanceExpenses() {
  while (true) {
    const { payload } = yield take(actions.updateFinanceExpenses);

    const rs = yield call(API.updateFinanceExpenses, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Expenses ${payload.name} were updated.`,
      }));

      yield put(actions.fetchFinanceExpenses({ app_ids: payload.app_ids, }));
    }
  }
}

function* sagaDeleteFinanceExpenses() {
  while (true) {
    const { payload } = yield take(actions.deleteFinanceExpenses);

    const rs = yield call(API.deleteFinanceExpenses, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Expenses ${payload.name} were deleted.`,
      }));

      yield put(actions.fetchFinanceExpenses({ app_ids: payload.app_ids, }));
    }
  }
}


function* sagaCreateExpensesTracker() {
  while (true) {
    const { payload } = yield take(actions.createExpensesTracker);

    const rs = yield call(API.createExpensesTracker, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Tracker was added.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchExpensesTrackers());
    }
  }
}

function* sagaUpdateExpensesTracker() {
  while (true) {
    const { payload } = yield take(actions.updateExpensesTracker);

    const rs = yield call(API.updateExpensesTracker, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Tracker was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchExpensesTrackers());
    }
  }
}

function* sagaDeleteExpensesTracker() {
  while (true) {
    const { payload } = yield take(actions.deleteExpensesTracker);

    const rs = yield call(API.deleteExpensesTracker, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Tracker was deleted.`,
      }));

      yield put(actions.fetchExpensesTrackers({ app_ids: payload.app_ids, }));
    }
  }
}


const sagas = [

  createFetchGenerator('fetchFinanceExpenses', actions),
  sagaCreateFinanceExpenses,
  sagaDeleteFinanceExpenses,
  sagaUpdateFinanceExpenses,

  createFetchGenerator('fetchFinanceNetworks', actions),

  createFetchGenerator('fetchExpensesTrackers', actions),
  sagaCreateExpensesTracker,
  sagaDeleteExpensesTracker,
  sagaUpdateExpensesTracker,

];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
