import { createActions, } from 'redux-actions';

import { createSuccessErrorActions, } from '../../helpers';


export default createActions(

  ...createSuccessErrorActions('FETCH_SCHEMA'),

  'UPDATE_SCHEMA',

  'CLEAR',

  { prefix: 'MUTATOR/JSON-SCHEMA', }

);
