import _ from 'lodash';
import { all, call, fork, takeEvery, put, select, } from 'redux-saga/effects';
import uuid from 'uuid/v1';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { Modal, } from 'src/components/ui';

import { AppActions, } from '../../actions';

import actions from './actions';

const ERROR_CODE_VERSION_ALREADY_UPDATED = 1;


function* sagaFetch() {
  yield takeEvery(actions.fetch, function* ({ payload }) {

    let rs = yield call(API.fetchMutatorHistory, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
      yield put(actions.fetchError());
    }
    else {
      yield put(actions.fetchFullHistorySuccess(rs.data));
      if (rs.data.length) {
        rs = yield call(API.fetchMutatorConfig, { ...payload, id: rs.data[0].id, });

        if (rs instanceof Error) {
          yield put(AppActions.showNotification({
            type: 'error',
            message: `Error`,
            description: getApiErrorText(rs),
          }));
          yield put(actions.fetchError());
        }
        else {
          yield put(actions.fetchSuccess(rs.data));
        }
      }
      else {
        yield put(actions.fetchSuccess({ snapshot: { groups: [], parameters: [], conditions: [], }, }));
      }
    }
  });
}

function* sagaPublish() {
  yield takeEvery(actions.publish, function* ({ payload }) {
    const { groups, parameters, conditions, version, } = yield select(state => state.mutator.config);

    const args = {};

    _.assign(args, payload, {
      groups: omitDates(groups),
      parameters: omitDates(parameters),
      conditions: omitDates(conditions)
        .map(i => ({
          ...i,
          segments: omitDates(i.segments),
          performed_events: omitDates(i.performed_events),
          idfm: i.idfm?.value ? i.idfm : null,
        })),
      base_version: version || 0,
      force_create: payload.force_create ?? false,
    });

    const rs = yield call(API.updateMutatorConfig, args);

    if (rs instanceof Error) {
      if (!payload.force_create && _.get(rs, 'response.data.data.code') === ERROR_CODE_VERSION_ALREADY_UPDATED) {
        try {
          yield call(confirmOverwrite);
          yield put(actions.publish({ ...payload, force_create: true, }));
        }
        catch (ex) {
          console.warn(ex);
          yield put(actions.discard());
        }
      }
      else {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(rs),
        }));
      }
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Config was published.`,
      }));
      yield put(actions.fetch({ app_project_id: payload.app_project_id, }));
    }
  });
}

function* sagaRollback() {
  yield takeEvery(actions.rollback, function* ({ payload }) {

    yield put(actions.discard());

    const { version, app_project_id, } = payload;

    let rs = yield call(API.revertMutatorVersion, { app_project_id, version: version.version, });

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Version was rollbacked.`,
      }));
      yield put(actions.fetch({ app_project_id, }));
    }
  });
}

function* sagaImport() {
  yield takeEvery(actions.importConfig, function* ({ payload }) {
    const projects = yield select(state => state.sdk.projects);

    const import_project = _.find(projects, { id: payload.import_project_id });

    const history = yield call(API.fetchMutatorHistory, { app_project_id: payload.import_project_id }, { companyId: import_project?.company_id });
    if (history instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(history),
      }));
    }
    else {
      const version = yield call(API.fetchMutatorConfig, { id: history?.data?.[0]?.id }, { companyId: import_project?.company_id });
      if (version instanceof Error) {
        yield put(AppActions.showNotification({
          type: 'error',
          message: `Error`,
          description: getApiErrorText(version),
        }));
      }
      else {
        const { groups, parameters, conditions, } = version?.data?.snapshot;

        const args = {};

        _.assign(args, {
          app_project_id: payload.app_project_id,
          groups: omitDates(groups),
          parameters: omitDates(parameters),
          conditions: omitDates(conditions)
            .map(i => ({
              ...i,
              segments: omitIDs(omitDates(i.segments)),
              performed_events: omitIDs(omitDates(i.performed_events)),
            })),
          base_version: 0,
          force_create: true,
          description: `Imported from ${_.find(projects, { id: +payload.import_project_id }).name}`,
        });

        args.groups.forEach(g => {
          const id = uuid({ clockseq: g.id, });
          args.parameters.forEach(p => {
            if (p.group_id === g.id) {
              p.group_id = id;
            }
          });
          g.id = id;
        });

        args.conditions.forEach(c => {
          const id = uuid({ clockseq: c.id, });
          args.parameters.forEach(p => {
            p.conditions.forEach(pc => {
              if (pc.condition_id === c.id) {
                pc.condition_id = id;
              }
            });
          });
          c.id = id;
        });

        const rs = yield call(API.updateMutatorConfig, args);

        if (rs instanceof Error) {
          yield put(AppActions.showNotification({
            type: 'error',
            message: `Error`,
            description: getApiErrorText(rs),
          }));
        }
        else {
          yield put(AppActions.showNotification({
            type: 'success',
            message: `Config was imported.`,
          }));
          yield put(actions.fetch({ app_project_id: payload.app_project_id, }));
        }
      }
    }
  });
}


const sagas = [
  sagaFetch,
  sagaPublish,
  sagaRollback,
  sagaImport,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}


function omitDates(collection) {
  return _.map(collection, i => _.omit(i, 'created_at', 'updated_at'));
}

function omitIDs(collection) {
  return _.map(collection, i => _.omit(i, 'id'));
}

function confirmOverwrite() {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: 'Version Conflict',
      content: 'RemoteConfig has been already updated since your last fetch. Do you want to overwrite all changes?',
      okText: 'Overwrite',
      okType: 'danger',
      onOk() { resolve() },
      cancelText: 'Discard',
      onCancel() { reject() },
    });
  });
}
