import _ from 'lodash';
import { all, takeEvery, call, put, fork, } from 'redux-saga/effects';

import API from 'src/service/api';

import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../helpers';

import { AppActions, SDKActions, MagnusActions, } from '../actions';


function* sagaCreateProject() {
  yield takeEvery(SDKActions.createProject, function* ({ payload }) {
    const rs = yield call(API.createAppProject, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Project ${payload.name} created.`,
      }));
      yield put(SDKActions.fetchProjects());
    }
  });
}

function* sagaUpdateProject() {
  yield takeEvery(SDKActions.updateProject, function* ({ payload }) {
    payload.services_tokens = _.mapValues(payload.services_tokens, v => {
      if (_.isArray(v) && !v.length) {
        return undefined;
      }
      if (_.isString(v) && !v) {
        return undefined;
      }
      return v;
    });

    const rs = yield call(API.updateAppProject, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs)
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Project ${payload.name} updated.`,
      }));
      yield put(SDKActions.fetchProjects());
    }
  });
}

function* sagaDeleteProject() {
  yield takeEvery(SDKActions.deleteProject, function* ({ payload }) {
    const args = _.pick(payload, ['id']),
      rs = yield call(API.deleteAppProject, args);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: 'Project deleted.',
      }));
      yield put(SDKActions.fetchProjects());
    }
  });
}

function* sagaCreateApplication() {
  yield takeEvery(SDKActions.createApplication, function* ({ payload }) {
    const rs = yield call(API.createProjectApplication, { app_project_id: payload.id });

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs)
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Application ${payload.name} created.`,
      }));
      yield put(SDKActions.fetchProjects());
      yield put(MagnusActions.fetchApps());
      yield put(MagnusActions.fetchCompanyApps());
    }
  });
}

function* sagaCreateProduct() {
  yield takeEvery(SDKActions.createProduct, function* ({ payload }) {
    const args = _.pick(payload, [
      'app_project_id',
      'name',
      'internal_id',
      'code',
      'type',
    ]);

    args.is_trial = false;

    const rs = yield call(API.createRevenueProduct, args);

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Product ${payload.code} created.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(SDKActions.fetchProducts({ app_project_ids: [payload.app_project_id] }));
    }
  });
}


function* sagaCreateProjectNote() {
  yield takeEvery(SDKActions.createProjectNote, function* ({ payload }) {
    const rs = yield call(API.createAppProjectNote, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Note created.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(SDKActions.fetchProjectNotes());
    }
  });
}

function* sagaUpdateProjectNote() {
  yield takeEvery(SDKActions.updateProjectNote, function* ({ payload }) {
    const rs = yield call(API.updateAppProjectNote, payload);

    if (rs instanceof Error) {
      yield processError(rs);
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Note updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(SDKActions.fetchProjectNotes());
    }
  });
}

function* sagaDeleteProjectNote() {
  yield takeEvery(SDKActions.deleteProjectNote, function* ({ payload }) {
    const args = _.pick(payload, ['id']),
      rs = yield call(API.deleteAppProjectNote, args);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: 'Note deleted.',
      }));

      yield put(SDKActions.fetchProjectNotes());
    }
  });
}

const sagas = [

  createFetchGenerator('fetchProjects', 'fetchAppProjects', SDKActions),
  sagaCreateProject,
  sagaUpdateProject,
  sagaDeleteProject,

  sagaCreateApplication,

  createFetchGenerator('fetchProducts', 'fetchRevenueProducts', SDKActions),
  sagaCreateProduct,

  createFetchGenerator('fetchProjectNotes', 'fetchAppProjectNotes', SDKActions),
  sagaCreateProjectNote,
  sagaUpdateProjectNote,
  sagaDeleteProjectNote,

  createFetchGenerator('fetchSegmentOptions', 'fetchSDKSegmentOptions', SDKActions),

  createFetchGenerator('fetchProjectHistory', 'fetchAppProjectHistory', SDKActions),

];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
