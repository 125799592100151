import _ from 'lodash';
import { all, call, fork, take, put, } from 'redux-saga/effects';

import API from 'src/service/api';
import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, processError, } from '../../helpers';

import { AppActions, } from '../../actions';

import actions from './actions';


function* sagaCreatePlacement() {
  while (true) {
    const { payload } = yield take(actions.createPlacement);

    const rs = yield call(API.createVariatorPlacement, {
      name: payload.name,
      is_required: payload.is_required ? 1 : 0,
      app_project_id: payload.app_project_id,
    });

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Trigger ${payload.name} was added.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchPlacements({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaUpdatePlacement() {
  while (true) {
    const { payload } = yield take(actions.updatePlacement);

    const rs = yield call(API.updateVariatorPlacement, {
      id: payload.id,
      name: payload.name,
      is_required: payload.is_required ? 1 : 0,
      app_project_id: payload.app_project_id,
    });

    if (rs instanceof Error) {
      yield processError(rs);
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Trigger ${payload.name} was updated.`,
      }));

      yield put(AppActions.dropModalForm());

      yield put(actions.fetchPlacements({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

function* sagaDeletePlacement() {
  while (true) {
    const { payload } = yield take(actions.deletePlacement);

    const rs = yield call(API.deleteVariatorPlacement, _.pick(payload, 'id'));

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    }
    else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Trigger ${payload.name} was deleted.`,
      }));

      yield put(actions.fetchPlacements({ app_project_ids: [payload.app_project_id] }));
    }
  }
}

const sagas = [
  createFetchGenerator('fetchPlacements', 'fetchVariatorPlacements', actions),

  sagaCreatePlacement,
  sagaUpdatePlacement,
  sagaDeletePlacement,
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
