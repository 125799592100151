import _ from 'lodash';
import { handleActions, } from 'redux-actions';

import actions from './actions';

const defaultState = {
  schema: null,
};

export default handleActions(
  {
    [actions.fetchSchemaSuccess]: (state, { payload }) => ({ ...state, schema: _.isArray(payload) ? payload[0] : _.isObject(payload) ? payload : null, }),
    [actions.clear]: () => defaultState,
  },
  defaultState
)
