import _ from 'lodash';

import API from 'src/service/api';


export function retrofy(method) {
  return (payload, ...args) => {
    return async function () {
      if (payload.retro_date) {
        let rs = await Promise.all([
          API[method]({ ...payload, retro_date: undefined, }, ...args),
          API[method]({ ...payload, created_date_end: payload.retro_date, retro_date: undefined, }, ...args),
        ]);

        if (rs instanceof Error) throw rs;

        let [{ data: gen_rs, }, { data: retro_rs, }] = rs;

        gen_rs = arr2obj(gen_rs);
        retro_rs = arr2obj(retro(retro_rs));

        rs = _.merge({}, gen_rs, retro_rs);
        rs = obj2arr(rs);

        return { payload: rs, };
      }
      else {
        const rs = await API[method](payload, ...args);
        if (rs instanceof Error) throw rs;
        return { payload: rs.data, };
      }
    }
  }
}


const keys = ['app_id', 'product_id', 'country', 'source', 'campaign_id', 'adgroup', 'creative'];
const retro_keys = ['cohort_sales_revenue', 'cohort_ads_revenue', 'cohort_revenue', 'cohort_profit', 'arpas', 'romi',];

const retro = obj =>
  ({
    ..._.pick(obj, keys),
    ..._.mapKeys(_.pick(obj, retro_keys), (v, k) => 'retro_' + k),
    data: obj.data ? _.map(obj.data, retro) : obj.data,
  });

const arr2obj = obj =>
  ({
    ...obj,
    data: obj.data ? _.keyBy(_.map(obj.data, arr2obj), i => keys.map(k => i[k]).join('---')) : obj.data,
  });

const obj2arr = obj => ({
  ...obj,
  data: obj.data ? _.map(obj.data, obj2arr) : obj.data,
});
