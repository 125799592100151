import { all, takeEvery, call, put, fork, } from 'redux-saga/effects';

import API from 'src/service/api';

import { getApiErrorText, } from 'src/helpers/errors-api';

import { createFetchGenerator, } from '../helpers';

import { AppActions, SubtruckActions, } from '../actions';

function* sagaCreateClient() {
  yield takeEvery(SubtruckActions.createClient, function* ({ payload }) {
    const rs = yield call(API.createSubtruckClient, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Client ${payload.name} created.`,
      }));
      yield put(AppActions.dropModalForm());
      yield put(SubtruckActions.fetchClients());
    }
  });
}

function* sagaUpdateClient() {
  yield takeEvery(SubtruckActions.updateClient, function* ({ payload }) {
    const rs = yield call(API.updateSubtruckClient, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs)
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: `Client ${payload.name} updated.`,
      }));
      yield put(AppActions.dropModalForm());
      yield put(SubtruckActions.fetchClients());
    }
  });
}

function* sagaDeleteClient() {
  yield takeEvery(SubtruckActions.deleteClient, function* ({ payload }) {
    const rs = yield call(API.deleteSubtruckClient, payload);

    if (rs instanceof Error) {
      yield put(AppActions.showNotification({
        type: 'error',
        message: `Error`,
        description: getApiErrorText(rs),
      }));
    } else {
      yield put(AppActions.showNotification({
        type: 'success',
        message: 'Client deleted.',
      }));
      yield put(SubtruckActions.fetchClients());
    }
  });
}

const sagas = [
  createFetchGenerator('fetchClients', 'fetchSubtruckClients', SubtruckActions),
  sagaCreateClient,
  sagaUpdateClient,
  sagaDeleteClient,

  createFetchGenerator('fetchSubtruckTransaction', SubtruckActions),
  createFetchGenerator('fetchSubtruckTransactionEvents', SubtruckActions),
  createFetchGenerator('fetchSubtruckTransactionEvent', SubtruckActions),
  createFetchGenerator('sendSubtruckFacebookPixelTestEvent', SubtruckActions),
  createFetchGenerator('sendSubtruckSnapchatPixelTestEvent', SubtruckActions),
];

export default function* () {
  yield all(sagas.map(item => fork(item)));
}
